<template>
  <div class="right-slide-info" v-if="site">
    <h1>
      {{ site.name }}
      <button class="btn p-0 float-right" @click="close()">
        <i class="fas fa-times text-muted"></i>
      </button>
    </h1>
    <div class="content">
      <table class="table">
        <tbody>
          <tr>
            <td>Site Code</td>
            <td>{{ site.site_code }}</td>
          </tr>
          <tr>
            <td>Location</td>
            <td>{{ Helper.formatCoordinate([site.lat, site.lng]) }}</td>
          </tr>
          <tr>
            <td>Region</td>
            <td>{{ site.region && site.region.name }}</td>
          </tr>
          <tr>
            <td>Permit Date</td>
            <td>
              {{ Helper.formatDate(site.permit_date) }}
              <span v-if="site.permit_date_end"> -<br />{{ Helper.formatDate(site.permit_date_end) }}</span>
            </td>
          </tr>
          <tr>
            <td>LUAS Permit Date</td>
            <td>
              {{ Helper.formatDate(site.luas_permit_date) }}
            </td>
          </tr>
          <tr>
            <td>Contractor</td>
            <td>{{ site.contractor?.name }}</td>
          </tr>
          <!-- <tr>
            <td>Product</td>
            <td>{{ site.product }}</td>
          </tr> -->
        </tbody>
      </table>
      <a class="btn btn-block btn-success" @click="viewSite()">View Site</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "site-info-popup",
  props: ["site"],
  watch: {
    site: {
      handler() {
        this.$nextTick(() => {
          if (this.site) {
            setTimeout(() => {
              $(this.$el).addClass("show");
            }, 100);
          } else {
            $(this.$el).removeClass("show");
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    viewSite() {
      this.$router.push({
        name: "site",
        params: {
          id: this.site._id,
        },
      });
    },
    close() {
      $(this.$el).removeClass("show");

      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>