<template>
  <div class="view-fullscreen has-navbar has-footer position-relative">
    <div id="map-region-dropdown">
      <div class="position-relative btn-group shadow-sm w-100">
        <button
          type="button"
          class="btn pl-3 pr-4 py-2 text-left"
          style="width: 200px"
        >
          <i class="fas fa-drafting-compass text-muted mr-2"></i>
          <span class="text-muted">
            {{ currentRegion ? currentRegion.name : "All Region" }}
          </span>
        </button>
        <button
          type="button"
          class="btn dropdown-toggle dropdown-toggle-split border border-default-dark no-focus"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item px-3 py-2" @click="currentRegion = null">
            All Region
          </button>
          <button
            v-for="region in regions"
            :key="region._id"
            class="dropdown-item px-3 py-2"
            @click="currentRegion = region"
          >
            {{ region.name }}
          </button>
          <div
            v-if="!isLoading && regions.length < 1"
            class="dropdown-item-text text-muted"
          >
            No region found
          </div>
        </div>
      </div>
      <date-picker-dropdown
        v-if="currentRegion"
        :value="date"
        class="mt-3 text-muted"
        @date-returned="dateSelected"
      />
    </div>
    <div id="dashboard-map" class="w-100 h-100"></div>
    <div id="map-footer" v-if="currentRegion">
      <div class="precipitation-legend">
        <p class="mb-2 text-default-dark">Rain Intensity</p>
        <ul class="list-unstyled mb-0 small">
          <li>
            <i class="fas fa-circle mr-2 text-info" />
            Light Rain (0.1mm - 2.5mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-primary" />
            Moderate Rain (2.6mm - 7.6mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-warning" />
            Heavy Rain (7.7mm - 50mm)
          </li>
          <li>
            <i class="fas fa-circle mr-2 text-danger" />
            Violent Rain (> 50mm)
          </li>
        </ul>
      </div>
    </div>
    <div class="map-floating-button" v-if="showMapButton">
      <button
        class="btn shadow"
        :class="isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'"
        data-toggle="tooltip"
        title="Toggle satellite view"
        @mouseenter="Helper.showTooltip($event)"
        @click="isSatelliteView = !isSatelliteView"
      >
        <i class="fas fa-satellite"></i>
      </button>
    </div>
    <site-info-popup :site="currentSite" @close="currentSite = null" />
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import Spinner from "@/components/Spinner.vue";
import SiteInfoPopup from "@/components/SiteInfoPopup.vue";
import DatePickerDropdown from "@/components/DatePickerDropdown";

export default {
  name: "view-map",
  components: {
    Spinner,
    SiteInfoPopup,
    DatePickerDropdown,
  },
  data() {
    return {
      isLoading: false,
      showMapButton: false,
      isSatelliteView: false,
      map: null,
      markers: [],
      sites: [],
      currentSite: null,
      regions: [],
      currentRegion: null,
      userRegions: [],
      userSites: [],
      date: this.Helper.formatDate(new Date(), "YYYY-MM-DD"),
    };
  },
  watch: {
    isSatelliteView: {
      handler() {
        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);
        }
      },
      immediate: false,
    },
    currentRegion: {
      async handler() {
        this.currentSite = null;

        let sites = [...this.sites];

        if (this.currentRegion) {
          this.isLoading = true;

          sites = sites.filter(
            (site) => site.region && site.region._id == this.currentRegion._id
          );

          sites = await this.getWeatherData(sites);

          this.isLoading = false;
        } else {
          sites = this.regions;
        }

        await this.setMarkers(sites.filter((site) => site.lat && site.lng));

        this.setMapBounds();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    getWeatherData(sites) {
      return new Promise(async (resolve, reject) => {
        const weatherPromises = sites
          .filter((site) => site.lat && site.lng)
          .map((site) => {
            return this.callWeatherAPI(site);
          });

        const response = await Promise.all(weatherPromises);

        resolve(response);
      });
    },
    callWeatherAPI(site) {
      return new Promise((resolve, reject) => {
        let url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${site.lat},${site.lng}/`;

        if (!this.date) {
          this.date = this.Helper.formatDate(new Date(), "YYYY-MM-DD");
        }

        url += `${this.date}?`;

        const query = {
          key: process.env.VUE_APP_VISUAL_CROSSING_KEY,
          timezone: "Asia/Kuala_Lumpur",
          unitGroup: "metric",
          include: "fcst,days,obs,stats,histfcst",
          elements: "precip",
        };

        Object.keys(query).forEach((key) => {
          url += `${key}=${query[key]}&`;
        });

        return this.API.getExternal(url)
          .then((retVal) => {
            site.precip = retVal.data.days[0].precip;
          })
          .finally(() => {
            resolve(site);
          });
      });
    },
    async dateSelected(e) {
      this.isLoading = true;

      this.date = e;

      let sites = [...this.sites];

      sites = sites.filter(
        (site) => site.region && site.region._id == this.currentRegion._id
      );

      sites = await this.getWeatherData(sites);

      await this.setMarkers(sites.filter((site) => site.lat && site.lng));

      this.isLoading = false;
    },
    initMap() {
      return new Promise((resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "dashboard-map",
          style: this.Helper.mapStyle(),
          center: [101.5179483, 3.075603444131706],
          zoom: 8,
        });

        this.map.on("load", async () => {
          await this.setMarkers(this.regions);

          this.setMapBounds();

          this.showMapButton = true;

          this.isLoading = false;

          resolve();
        });
      });
    },
    setMapBounds() {
      if (this.markers.length > 0 && this.markers.length <= 1) {
        this.map.setCenter([
          this.markers[0]._lngLat.lng,
          this.markers[0]._lngLat.lat,
        ]);
      } else if (this.markers.length > 1) {
        let bounds = new mapboxgl.LngLatBounds();

        this.markers.forEach(function (marker) {
          bounds.extend([marker._lngLat.lng, marker._lngLat.lat]);
        });

        this.map.fitBounds(bounds, {
          padding: 150,
          duration: 0,
        });
      }
    },
    async setMarkers(sites) {
      return new Promise((resolve) => {
        this.markers.forEach((marker) => {
          marker.remove();
        });

        this.markers = [];

        sites.forEach((site, index) => {
          var el = document.createElement("div");

          el.className = "dashboard-marker";

          el.style.zIndex = index + 10;

          el.innerHTML =
            "<div class='marker-label font-weight-medium'>" +
            site.name +
            `${
              this.currentRegion
                ? site.precip
                  ? `<span class='badge badge-${this.rainIntensityClass(
                      site.precip
                    )} ml-3'>${site.precip}mm</span>`
                  : "<span class='badge badge-secondary ml-3'>--</span>"
                : ""
            }`;
          ("</div>");

          let marker = new mapboxgl.Marker(el).setLngLat([site.lng, site.lat]);

          el.addEventListener("mouseenter", () => {
            el.style.zIndex = index + 10 + sites.length;
          });

          el.addEventListener("mouseleave", () => {
            el.style.zIndex = index + 10;
          });

          el.addEventListener("click", () => {
            if (site.region) {
              this.currentSite = site;
            } else {
              this.currentRegion = site;
            }
          });

          this.markers.push(marker);

          marker.addTo(this.map);
        });

        if (this.regions.length == 1) {
          this.currentRegion = this.regions[0];
        }

        resolve();
      });
    },
    rainIntensityClass(precip) {
      let className = "secondary";

      if (precip > 0 && precip <= 2.5) {
        className = "info"; // light rain
      } else if (precip > 2.5 && precip <= 7.6) {
        className = "primary"; // moderate rain
      } else if (precip > 7.6 && precip <= 50) {
        className = "warning"; // heavy rain
      } else if (precip > 50) {
        className = "danger"; // violent rain
      }

      return className;
    },
    async getSites() {
      let url = "sites?_sort=name";

      if (this.userRegions.length > 0) {
        url += `&region_in=${this.userRegions.join("&region_in=")}`;
      }

      if (this.userSites.length > 0) {
        url += `&id_in=${this.userSites.join("&id_in=")}`;
      }

      const [siteCall, siteCallErr] = await this.Helper.handle(
        this.API.get(url)
      );

      if (!siteCallErr && siteCall.status == 200) {
        this.sites = siteCall.data;
      }

      this.initMap();
    },
    async getRegions() {
      if (this.userRegions && this.userRegions.length > 0) {
        const [regionCall, regionCallErr] = await this.Helper.handle(
          this.API.get(`regions?_id_in=${this.userRegions.join("&_id_in=")}`)
        );

        if (!regionCallErr && regionCall.status == 200) {
          this.regions = regionCall.data.filter(
            (region) => region.name !== "Central Region"
          );

          this.getSites();
        } else {
          this.isLoading = false;
        }
      } else {
        this.initMap();
      }
    },
    async getUserRegions() {
      this.isLoading = true;

      const [userCall, userCallErr] = await this.Helper.handle(
        this.API.get("users/me")
      );

      if (!userCallErr && userCall.status == 200) {
        this.userRegions = userCall.data.regions ? userCall.data.regions : [];

        this.userSites = userCall.data.sites ? userCall.data.sites : [];

        this.getRegions();
      } else {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getUserRegions();
  },
};
</script>